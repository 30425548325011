<template>
    <div class="field-list-item-box">
        <!-- 图片 -->
        <router-link 
            :to="{ 
                name: 'fieldDetail', 
                query: { id: itemData.id }
            }"
            class="field-list-item-pic">
            <el-image
                :src="itemData.img"
                fit="cover"
                lazy
                class="field-list-item-pic-box">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </router-link>
        <!-- 文本 -->
        <div class="field-list-item-text">
            <!-- 标题 -->
            <router-link 
                :to="{ 
                    name: 'fieldDetail', 
                    query: { id: itemData.id }
                }"
                class="field-list-item-text-title">
                {{itemData.name}}
            </router-link>
            <!-- 价格 -->
            <div class="field-list-item-text-price">
                <span>{{itemData.price}}</span>
                <span>元/小时</span>
            </div>
            <!-- 地标 -->
            <el-tooltip 
                effect="dark" 
                placement="bottom-start"
                :content="itemData.address">
                <div class="field-list-item-text-address">
                    <i class="el-icon-location"></i>
                    {{itemData.address}}
                </div>
            </el-tooltip>
            <!-- 标签 -->
            <div class="field-list-item-text-tag">
                <span v-for="item in itemData.tag"
                    :key="item.id">
                    {{item}}
                </span>
            </div>
            <!-- 其它 -->
            <div class="field-list-item-text-other">
                <!-- 立即预定 -->
                <router-link :to="{ 
                        name: 'fieldDetail', 
                        query: { id: itemData.id }
                    }"
                    class="field-list-item-text-reserve">
                    立即预定
                    <i class="el-icon-d-arrow-right"></i>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ "itemData" ]
}
</script>

<style scoped>
/* 组件容器 */
.field-list-item-box{
    border-radius: 15px;
    overflow: hidden;
    background-color: #fff;
}
/* 图片 */
.field-list-item-pic{
    position: relative;
    display: block;
    width: 100%;
}
.field-list-item-pic::before{
    display: block;
    content: "";
    padding-top: 60%;
}
.field-list-item-pic-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
}
/* 文本 */
.field-list-item-text{
    padding: 15px;
    padding-top: 0;
}
/* 标签 */
.field-list-item-text-tag>span{
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    padding: 0 10px;
    border-radius: 15px;
    color: #fff;
    background-color: #E6A23C;
}
/* 标题 */
.field-list-item-text-title{
    display: block;
    width: 100%;
    padding-bottom: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #303133;
}
.field-list-item-text-title:hover{
    color: #8d9afc;
}
/* 价格 */
.field-list-item-text-price{
    display: block;
    text-align: right;
}
.field-list-item-text-price>span:first-child{
    margin-right: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #E6A23C;
}
.field-list-item-text-price>span:last-child{
    color: #C0C4CC
}
/* 地址 */
.field-list-item-text-address{
    width: 100%;
    margin-bottom: 10px;
    color: #C0C4CC;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* 立即预定 */
.field-list-item-text-other{
    text-align: right;
}
.field-list-item-text-reserve{
    color: #8d9afc
}
</style>