var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field-list-item-box"},[_c('router-link',{staticClass:"field-list-item-pic",attrs:{"to":{ 
            name: 'fieldDetail', 
            query: { id: _vm.itemData.id }
        }}},[_c('el-image',{staticClass:"field-list-item-pic-box",attrs:{"src":_vm.itemData.img,"fit":"cover","lazy":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-loading"})])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-picture-outline"})])]},proxy:true}])})],1),_c('div',{staticClass:"field-list-item-text"},[_c('router-link',{staticClass:"field-list-item-text-title",attrs:{"to":{ 
                name: 'fieldDetail', 
                query: { id: _vm.itemData.id }
            }}},[_vm._v(" "+_vm._s(_vm.itemData.name)+" ")]),_c('div',{staticClass:"field-list-item-text-price"},[_c('span',[_vm._v(_vm._s(_vm.itemData.price))]),_c('span',[_vm._v("元/小时")])]),_c('el-tooltip',{attrs:{"effect":"dark","placement":"bottom-start","content":_vm.itemData.address}},[_c('div',{staticClass:"field-list-item-text-address"},[_c('i',{staticClass:"el-icon-location"}),_vm._v(" "+_vm._s(_vm.itemData.address)+" ")])]),_c('div',{staticClass:"field-list-item-text-tag"},_vm._l((_vm.itemData.tag),function(item){return _c('span',{key:item.id},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"field-list-item-text-other"},[_c('router-link',{staticClass:"field-list-item-text-reserve",attrs:{"to":{ 
                    name: 'fieldDetail', 
                    query: { id: _vm.itemData.id }
                }}},[_vm._v(" 立即预定 "),_c('i',{staticClass:"el-icon-d-arrow-right"})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }